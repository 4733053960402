import styled from 'styled-components';
import BREAKPOINTS from '../../constants/breakpoints';

export const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 1rem;
  max-width: ${BREAKPOINTS.FULL}px;

  a {
    color: #757575;
    transition: color 0.2s ease;
    text-decoration: none;

    &:hover {
      color: inherit;
    }
  }
`;

export const StickyHeader = styled.div`
  display: block;
  background-color: white;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 15;
  height: 64px;
  border-bottom: 2px solid #f5f5f5;
`;
