import styled from 'styled-components';
import { Link } from 'react-scroll';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.nav`
  ul {
    display: flex;
    list-style: none;
    padding: 0;

    li {
      font-size: 1.4rem;
      margin-right: 2rem;
    }
  }

  ${MEDIA.TABLET`
    display: none;
  `};
`;

export const StyledLink = styled(Link)`
  padding: 0.5rem 1rem;
  cursor: pointer;
  text-decoration: none;
`;
