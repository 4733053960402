import React from 'react';
import PropTypes from 'prop-types';
import posed from 'react-pose';
import { StickyHeader, Container } from './header.css';
import Nav from 'components/header/nav';

import Logo from '!svg-react-loader!../../images/bidcentral_logo.svg';

// Example of a component-specific page transition
const AnimatedContainer = posed.div({
  enter: {
    y: 0,
    transition: {
      ease: 'easeInOut',
    },
  },
  exit: {
    y: '-100%',
    transition: {
      ease: 'easeInOut',
    },
  },
});

const Header = ({ title }) => (
  <AnimatedContainer>
    <StickyHeader>
      <Container>
        <a href="https://bidcentral.ca">
          <Logo style={{ width: '160px', marginBottom: '1rem' }} alt={title} />
        </a>
        <Nav />
      </Container>
    </StickyHeader>
  </AnimatedContainer>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Header;
