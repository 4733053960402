import styled from 'styled-components';
import { accent, grey } from 'constants/theme';
// import BREAKPOINTS from '../../constants/breakpoints';

const bgColorChooser = color => {
  if (color == 'primary') return accent;
  else if (color == 'secondary') return grey;
  else return 'white';
};

const fontColorChooser = color => {
  if (color == 'primary') return 'white';
  else return 'black';
};

export const Background = styled.div`
  text-align: ${props => props.align}
  background-color: ${props => bgColorChooser(props.color)};
  color: ${props => fontColorChooser(props.color)};
`;
