import React from 'react';
import { Container, StyledLink } from './nav.css';

const Nav = () => (
  <Container>
    <ul>
      <li>
        <StyledLink
          to="intro"
          spy
          smooth
          duration={500}
          offset={-64}
          activeClass="active"
        >
          Home
        </StyledLink>
      </li>
      <li>
        <StyledLink
          to="about"
          spy
          smooth
          duration={500}
          offset={-64}
          activeClass="active"
        >
          About
        </StyledLink>
      </li>
      <li>
        <StyledLink
          to="features"
          spy
          smooth
          duration={500}
          offset={-64}
          activeClass="active"
        >
          Features
        </StyledLink>
      </li>
      <li>
        <StyledLink
          to="pricing"
          spy
          smooth
          duration={500}
          offset={-64}
          activeClass="active"
        >
          Pricing
        </StyledLink>
      </li>
      <li>
        <StyledLink
          to="pricing"
          spy
          smooth
          duration={500}
          offset={-64}
          activeClass="active"
        >
          Buy Now
        </StyledLink>
      </li>
    </ul>
  </Container>
);

export default Nav;
