import React from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';
import { Background } from './section.css';

const Section = ({ children, color, align = 'left', name }) => {
  return (
    <Element name={name}>
      <Background color={color} align={align}>
        {children}
      </Background>
    </Element>
  );
};

Section.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  align: PropTypes.oneOf(['center']),
  name: PropTypes.string,
};

export default Section;
