import React from 'react';
import PropTypes from 'prop-types';
import Section from 'components/section';
import {
  Container,
  Title,
  Content,
  Contact,
  Social,
  SocialIcon,
} from './footer.css';

const Footer = ({ copy, phone, email, social }) => (
  <Section color="primary">
    <Container>
      <div>
        <Title>BidCentral</Title>
        <Content>{copy}</Content>
        <Content>&copy; 2018 BidCentral</Content>
      </div>

      <div>
        <ul>
          {social.map((icon, key) => (
            <Social key={key}>
              <a href={icon.url}>
                <SocialIcon
                  fluid={icon.image ? icon.image.childImageSharp.fluid : {}}
                />
              </a>
            </Social>
          ))}
        </ul>
        <Contact>Call: {phone}</Contact>
        <Contact>{email}</Contact>
      </div>
    </Container>
  </Section>
);

Footer.propTypes = {
  copy: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  social: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
