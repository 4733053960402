import styled from 'styled-components';
import Image from 'gatsby-image';
import { accent } from 'constants/theme';
import BREAKPOINTS from '../../constants/breakpoints';

export const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 2rem;
  max-width: ${BREAKPOINTS.FULL}px;

  font-size: 1.3rem;

  color: white;
  background-color: ${accent} a {
    color: #757575;
    transition: color 0.2s ease;
    text-decoration: none;

    &:hover {
      color: inherit;
    }
  }
`;

export const Content = styled.p`
  font-size: 13px;
  font-weight: 400;
  color: #364655;
  color: #fff;
  letter-spacing: 0;
  line-height: 1.4;
  max-width: 320px;
  margin-bottom: 10px;
`;

export const Title = styled.h2`
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 10px;
`;

export const Contact = styled.p`
  margin: 5px 5px 0 0;
  font-family: 'Montserrat';
  font-size: 13px;
  letter-spacing: 0;
  color: #364655;
  color: #d8d8d8;
  line-height: 1.6;
`;

export const Social = styled.div`
  display: inline-block;
`;

export const SocialIcon = styled(Image)`
  width: 35px;
`;
